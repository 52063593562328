import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from "@/stores/user"
import HomeView from '../views/HomeView.vue'
import Phrase from '../components/Phrase'
import Print from '../components/Print'
import BlogView from "@/views/Blog.vue"
import CategoryView from "@/views/Category.vue"
import PostView from "@/views/Post.vue"
import SignInView from "@/views/SignIn.vue"
import RegisterView from "@/views/Register.vue"
import Democalc from "@/views/Democalc.vue"
import Testcalc from "@/views/Testcalc.vue"
import PaymentView from "@/views/Payment.vue"
import PNDPolicyView from "@/views/PNDPolicy.vue"
import ArticleView from "@/views/Article.vue"
import OfertaView from "@/views/Oferta.vue"
import UserProfile from "@/views/UserProfile.vue"
import PaymentSuccessView from "@/views/PaymentSuccess.vue"
import PaymentFailView from "@/views/PaymentFail.vue"
import SitemapComponent from '@/components/SitemapComponent.vue'
import { loadLayoutMiddleware } from "@/middleware/loadLayoutMiddleware";
import LearningSets from '@/components/LearningSets.vue';
import LearningSetPurchase from '@/views/learning-sets/LearningSetPurchase.vue';
import LearningSetDetail from '@/views/learning-sets/LearningSetDetail.vue';
export const routes = [
  {
    name: 'index',
    path: '/',
    component: HomeView,
    meta: {
      layout: "MainLayout"
    }
  },
  {
    name: 'paymentSucess',
    path: '/payment/success',
    component: PaymentSuccessView
  },
  {
    name: 'paymentFail',
    path: '/payment/fail',
    component: PaymentFailView
  },
  {
    name: 'blog',
    path: '/blog',
    component: BlogView
  },
  {
    path: "/category/:category",
    name: "Category",
    component: CategoryView,
  },
  {
    path: "/post/:slug",
    name: "Post",
    component: PostView,
  },
  {
    path: '/app',
    name: 'app',
    component: Phrase,
    meta: { requiresAuth: true ,requiresSubscription: true },
  },
  {
    path: '/print',
    name: 'print',
    component: Print,
    meta: { requiresAuth: true },
    meta: {
      layout: "BlankLayout"
    }
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignInView,
    meta: { requiresUnauth: true }
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: { requiresUnauth: true }
  },
  {
    path: "/democalc",
    name: "democalc",
    component: Democalc,
  },
  {
    path: "/testcalc",
    name: "testcalc",
    component: Testcalc,
  },
  {
    path: '/profile',
    name: 'profile',
    component: UserProfile,
    meta: { requiresAuth: true}
  },
  {
    path: "/pndpolicy",
    name: "PNDPolicy",
    component: PNDPolicyView,
  },
  {
    path: "/article",
    name: "ArticleView",
    component: ArticleView,
  },
  {
    path: "/oferta",
    name: "OfertaView",
    component: OfertaView,
  },
  {
    path: "/payment",
    name: "PaymentView",
    component: PaymentView,
    meta: { requiresAuth: true },
  },
  {
    path: "/nabory/",
    name: "LearningSets",
    component: LearningSets,
  },
  {
    path: '/nabory/:id/kupit',
    name: 'learning-set-purchase',
    component: LearningSetPurchase,
  },
  {
    path: '/nabory/:id',
    name: 'learning-set-details',
    component: LearningSetDetail,
  },
  {
    path: '/sitemap',
    component: SitemapComponent,
    props: {
      baseUrl: 'https://artmandala.live'
    },
    // This will create a custom layout wrapper for the sitemap
    meta: {
      layout: "BlankLayout"
    }
  }
]
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})
router.beforeEach(loadLayoutMiddleware)
// Global navigation guard
router.beforeEach(async (to, from, next) => {
  // Initialize the store
  const store = useAuthStore()
  console.log(store)

  // Check if the route requires authentication
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth)
  const requiresSubscription = to.matched.some(record => record.meta.requiresSubscription)

  // Get authentication status
  const isAuthenticated = store.isAuthenticated
  const subscribed = store.getSubscriptionStatus

  // Handle authentication logic
  if (requiresAuth) {
    if (!isAuthenticated) {
      // Redirect to login page and save the intended destination
      next({
        name: 'SignIn',
        query: { redirect: to.fullPath }  // Save the path user tried to access
      })
      return
    }
    if (requiresSubscription){
      console.log('need sub')
      if (!subscribed) {
        console.log('not subscrided')
        // Redirect to payment page and save the intended destination
        next({
          name: 'PaymentView',
          query: { redirect: to.fullPath }  // Save the path user tried to access
        })
        return
      }
    }
    // Verify token validity if needed
    try {
      // Optional: Add token verification logic here
      // await store.verifyToken()
      next()
    } catch (error) {
      console.error('Token verification failed:', error)
      store.logout()
      next({
        name: 'SignIn',
        query: { redirect: to.fullPath }
      })
    }
  }
  // Handle routes that require users to be logged out
  else if (requiresUnauth && isAuthenticated) {
    next({ name: 'app' })  // Redirect to app if user is already logged in
  }

  else {
    next()
  }
})

export default router
