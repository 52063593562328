<template>
    <div class="min-h-screen bg-gray-50">
      <!-- Loading State -->
      <div v-if="isLoading" class="flex justify-center items-center min-h-[400px]">
        <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
      </div>

      <!-- Error State -->
      <div v-else-if="error" class="max-w-7xl mx-auto px-4 py-12 text-center">
        <div class="bg-red-50 p-4 rounded-lg">
          <p class="text-red-600">{{ error }}</p>
          <button
            @click="fetchLearningSet"
            class="mt-4 text-purple-600 hover:text-purple-700 font-medium"
          >
            Попробовать снова
          </button>
        </div>
      </div>

      <!-- Main Content -->
      <div v-else-if="learningSet" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <!-- Breadcrumbs -->
        <nav class="mb-8">
          <ol class="flex space-x-2 text-sm text-gray-500">
            <li><router-link to="/" class="hover:text-purple-600">Главная</router-link></li>
            <li>/</li>
            <li><router-link to="/nabory" class="hover:text-purple-600">Наборы</router-link></li>
            <li>/</li>
            <li class="text-purple-600">{{ learningSet.name }}</li>
          </ol>
        </nav>

        <!-- Header Section -->
        <div class="bg-white rounded-2xl shadow-sm overflow-hidden mb-8">
          <div class="p-8">
            <div class="flex items-start gap-8">
              <div class="flex-1">
                <div class="flex items-center gap-4 mb-4">
                  <h1 class="text-3xl font-bold text-gray-900">{{ learningSet.name }}</h1>

                </div>
                <!-- <p class="text-lg text-gray-600 mb-6">{{ learningSet.description_short }}</p> -->

                <!-- Price Section -->
                <div class="flex items-baseline gap-4 mb-6">
                  <span class="text-3xl font-bold text-purple-600">{{ formatPrice(learningSet.price) }}₽</span>
                  <span class="text-xl text-gray-400 line-through">{{ formatPrice(learningSet.old_price) }}₽</span>
                  <span class="bg-yellow-100 text-yellow-800 px-3 py-1 rounded-full text-sm font-medium">
                    Скидка {{ calculateDiscount(learningSet.price, learningSet.old_price) }}%
                  </span>
                </div>

                <!-- Action Buttons -->
                <div class="flex gap-4">
                  <button
                    @click="handlePurchase"
                    class="bg-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-purple-700 transition-colors"
                  >
                    Купить сейчас
                  </button>
                  <button
                    @click="showDemoContent"
                    class="border border-purple-600 text-purple-600 px-8 py-3 rounded-lg font-medium hover:bg-purple-50 transition-colors"
                  >
                    Посмотреть пример
                  </button>
                </div>
              </div>

              <!-- Preview Image -->
              <div class="w-64 h-64 flex-shrink-0">
                <img v-if="learningSet.icon_url" :src="learningSet.icon_url" :alt="learningSet.name" class="w-12 h-12 object-contain" />
              </div>
            </div>
          </div>
        </div>

        <!-- Content Sections -->
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <!-- Main Content -->
          <div class="lg:col-span-2">
            <!-- What's Included -->
            <div class="bg-white rounded-2xl shadow-sm p-8 mb-8">
              <h2 class="text-2xl font-bold mb-6">Что входит в набор</h2>
              <ul class="space-y-4">
                <li class="flex items-start gap-3">
                  <span class="text-purple-500 mt-1">✓</span>
                  <div>
                    <h3 class="font-medium">{{ learningSet.included_items }}</h3>
                  </div>
                </li>
                <li class="flex items-start gap-3">
                  <span class="text-purple-500 mt-1">✓</span>
                  <div>
                    <h3 class="font-medium">Инструкция</h3>
                  </div>
                </li>
              </ul>
            </div>

            <!-- Description -->
            <div class="bg-white rounded-2xl shadow-sm p-8 mb-8">
              <h2 class="text-2xl font-bold mb-6">Описание</h2>
              <div class="max-w-none" v-html="learningSet.description"></div>
            </div>

            <!-- Benefits -->
            <div class="bg-white rounded-2xl shadow-sm p-8">
              <h2 class="text-2xl font-bold mb-6">Преимущества набора</h2>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div
                  v-for="(benefit, index) in benefits"
                  :key="index"
                  class="flex items-start gap-4"
                >
                  <div class="w-12 h-12 bg-purple-100 rounded-xl flex items-center justify-center flex-shrink-0">
                    <span class="text-purple-500 mt-1">✓</span>
                  </div>
                  <div>
                    <h3 class="font-medium mb-2">{{ benefit }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Sidebar -->
          <div class="lg:col-span-1">
            <!-- Quick Info Card -->
            <div class="bg-white rounded-2xl shadow-sm p-6 sticky top-8">
              <h3 class="font-bold mb-4">Краткая информация</h3>
              <ul class="space-y-4">
                <li class="flex items-center justify-between">
                  <span class="text-gray-600">Формат</span>
                  <span class="font-medium">PDF</span>
                </li>
                <li class="flex items-center justify-between">
                  <span class="text-gray-600">Язык</span>
                  <span class="font-medium">Русский</span>
                </li>
                <li class="flex items-center justify-between">
                  <span class="text-gray-600">Обновлено</span>
                  <span class="font-medium">{{ updatedDate }}</span>
                </li>
              </ul>

              <hr class="my-6">

              <div class="space-y-4">
                <div class="flex items-center gap-3 text-gray-600">
                  <span class="text-purple-500">✓</span>
                  Мгновенный доступ
                </div>
                <div class="flex items-center gap-3 text-gray-600">
                  <span class="text-purple-500">✓</span>
                  Пожизненный доступ к материалам
                </div>
                <div class="flex items-center gap-3 text-gray-600">
                  <span class="text-purple-500">✓</span>
                  Поддержка по email
                </div>
              </div>

              <button
                @click="handlePurchase"
                class="w-full bg-purple-600 text-white py-3 rounded-lg font-medium hover:bg-purple-700 transition-colors mt-6"
              >
                Купить за {{ formatPrice(learningSet.price) }}₽
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script setup>
  import { ref, computed, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import axiosInstance from '@/axios/axios'

  const route = useRoute()
  const router = useRouter()
  const learningSet = ref(null)
  const isLoading = ref(true)
  const error = ref(null)
  const benefits = [
    'Начните работать над ситуацией прямо сейчас',
    'Пожизненный доступ к материалам',
    'Пошаговая инструкция',
    'Бесплатная расшифровка ваших результатов'
  ]
  const updatedDate = computed(() => {
    const today = new Date()
    const date = new Date(today.setDate(today.getDate() - 21))
    return new Intl.DateTimeFormat('ru-RU', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).format(date)
  })

  const fetchLearningSet = async () => {
    isLoading.value = true
    error.value = null

    try {
      const response = await axiosInstance.get(`/draw/api/v1/free/learningsets/${route.params.id}`)
      learningSet.value = response.data
    } catch (err) {
      console.error('Error fetching learning set:', err)
      error.value = 'Не удалось загрузить информацию о наборе'
    } finally {
      isLoading.value = false
    }
  }

  const handlePurchase = () => {
    router.push(`/nabory/${route.params.id}/kupit`)
  }

  const showDemoContent = () => {
    // Implement demo content preview logic
    console.log('Show demo content')
  }

  const formatPrice = (price) => {
    return new Intl.NumberFormat('ru-RU').format(price)
  }

  const calculateDiscount = (currentPrice, originalPrice) => {
    return Math.round((1 - currentPrice / originalPrice) * 100)
  }

  onMounted(() => {
    fetchLearningSet()
  })
  </script>

  <style scoped>
  .prose {
    max-width: 65ch;
  }

  .prose h2 {
    color: #1a1a1a;
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
  }

  .prose p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }

  .prose ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em;
  }

  .prose li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  </style>
