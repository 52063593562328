<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
    <!-- Loading State -->
    <div v-if="loading" class="flex justify-center items-center min-h-[400px]">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="text-red-500 text-center">{{ error }}</div>

    <!-- Content -->
    <div v-else>
      <!-- Pricing Banner -->
<div class="bg-gradient-to-r from-purple-100 to-purple-50 p-6 rounded-xl mb-8 text-center">
  <div class="text-3xl font-bold text-purple-900 mb-2">
    Все наборы по фиксированной цене
  </div>
  <div class="flex items-center justify-center gap-4">
    <span class="text-gray-500 line-through text-xl">1390₽</span>
    <span class="text-3xl font-bold text-purple-600">990₽</span>
  </div>
  <div class="text-purple-700 mt-2">Специальное предложение</div>
</div>

<!-- Topics Navigation -->
      <div class="mb-12">
        <h2 class="text-3xl font-bold text-gray-900 text-center mb-8">
          Наборы для обучения и самопознания
        </h2>
        <div class="flex flex-wrap gap-4 justify-center">
          <button
            v-for="topic in learningSets"
            :key="topic.id"
            @click="selectedTopic = selectedTopic === topic.id ? null : topic.id"
            :class="[
              'px-6 py-3 rounded-full transition-all',
              selectedTopic === topic.id
                ? 'bg-purple-600 text-white'
                : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
            ]"
          >
            {{ topic.name }}
          </button>
        </div>
      </div>

      <!-- Learning Sets Grid -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8 auto-rows-fr">
        <div
          v-for="topic in filteredTopics"
          :key="topic.id"
          class="contents"
        >
          <div
            v-for="set in topic.learning_sets"
            :key="set.id"
            class="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 h-full flex flex-col"
          >
            <div class="p-6 flex flex-col h-full">
              <div class="flex items-start justify-between">
                <div class="flex-1">
                  <h3 class="text-lg font-semibold text-gray-900 mb-2 line-clamp-2">
                    {{ set.name }}
                  </h3>
                  <p class="text-sm text-gray-600 mb-4">
                    {{ topic.description_short }}
                  </p>
                </div>
                <img
                  :src="set.icon_url"
                  :alt="set.name"
                  class="w-16 h-16 object-cover rounded ml-4 flex-shrink-0"
                />
              </div>

              <div class="mt-auto pt-4 flex justify-between items-center">
                <button
                  class="inline-flex items-center px-4 py-2 border border-purple-600 text-purple-600 rounded hover:bg-purple-50 transition-colors"
                  @click="navigateToSet(set.slug)"
                >
                  Подробнее
                </button>
                <button
                  class="inline-flex items-center px-4 py-2 bg-yellow-400 text-white rounded hover:bg-yellow-500 transition-colors"
                  @click="navigateToPurchase(set.slug)"
                >
                  Купить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Benefits Section -->
      <div class="mt-16 bg-purple-50 rounded-2xl p-8">
        <h3 class="text-2xl font-bold text-center mb-8">
          Почему стоит приобрести наши наборы?
        </h3>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div
            v-for="(benefit, index) in benefits"
            :key="index"
            class="text-center"
          >
            <div class="bg-white w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
              <span class="text-2xl">{{ benefit.icon }}</span>
            </div>
            <h4 class="font-semibold mb-2">{{ benefit.title }}</h4>
            <p class="text-gray-600">{{ benefit.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axiosInstance from '@/axios/axios';
import { useRouter } from 'vue-router';

export default {
  name: 'LearningSets',

  setup() {
    const router = useRouter();
    const learningSets = ref([]);
    const selectedTopic = ref(null);
    const loading = ref(true);
    const error = ref(null);

    const benefits = [
      {
        icon: '🎯',
        title: 'Целенаправленная работа',
        description: 'Каждый набор создан для решения конкретной жизненной задачи'
      },
      {
        icon: '⚡',
        title: 'Быстрый результат',
        description: 'Проверенные техники для достижения желаемых изменений'
      },
      {
        icon: '🔄',
        title: 'Постоянный доступ',
        description: 'Скачивайте и используйте материалы в любое удобное время'
      }
    ];

    const filteredTopics = computed(() => {
      if (!selectedTopic.value) return learningSets.value;
      return learningSets.value.filter(topic => topic.id === selectedTopic.value);
    });

    const fetchLearningSets = async () => {
      try {
        loading.value = true;
        const response = await axiosInstance.get('/draw/api/v1/free/learningsets/');
        learningSets.value = response.data.data;
      } catch (err) {
        console.error('Error fetching learning sets:', err);
        error.value = 'Failed to load learning sets';
      } finally {
        loading.value = false;
      }
    };

    const navigateToSet = (slug) => {
      router.push(`/nabory/${slug}`);
    };

    const navigateToPurchase = (slug) => {
      router.push(`/nabory/${slug}/kupit`);
    };

    onMounted(() => {
      fetchLearningSets();
    });

    return {
      learningSets,
      selectedTopic,
      loading,
      error,
      benefits,
      filteredTopics,
      navigateToSet,
      navigateToPurchase
    };
  }
};
</script>

<style scoped>
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
