<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axiosInstance from '@/axios/axios'

const route = useRoute()
const router = useRouter()
const learningSet = ref(null)
const isLoading = ref(false)
const error = ref(null)
const email = ref('')
const emailError = ref('')

const fetchLearningSet = async () => {
  isLoading.value = true
  error.value = null

  try {
    const response = await axiosInstance.get(`/draw/api/v1/free/learningsets/${route.params.id}`)
    learningSet.value = response.data
  } catch (err) {
    console.error('Error fetching learning set:', err)
    error.value = err.message
  } finally {
    isLoading.value = false
  }
}

const validateEmail = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (!email.value) {
    emailError.value = 'Пожалуйста, введите email'
    return false
  }
  if (!emailRegex.test(email.value)) {
    emailError.value = 'Пожалуйста, введите корректный email'
    return false
  }
  emailError.value = ''
  return true
}

const handlePurchase = async () => {
  if (isLoading.value) return
  if (!validateEmail()) return

  isLoading.value = true
  try {
    const response = await axiosInstance.post(`/draw/api/v1/free/learningsets/purchase/${route.params.id}/`, {
      email: email.value
    })

    if (response.data.payment_link) {
      window.location.href = response.data.payment_link
    }
  } catch (err) {
    console.error('Purchase error:', err)
    error.value = 'Ошибка при оформлении покупки. Пожалуйста, попробуйте позже.'
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  fetchLearningSet()
})
</script>

<template>
  <div class="max-w-4xl mx-auto px-4 py-12">
    <div v-if="learningSet" class="bg-white rounded-2xl shadow-lg overflow-hidden">
      <!-- Header -->
      <div class="bg-purple-50 p-8">
        <h1 class="text-3xl font-bold text-gray-900 mb-4">Оформление заказа</h1>
        <div class="flex items-center gap-6">
          <div class="w-24 h-24 bg-purple-100 rounded-lg flex items-center justify-center">
            <span class="text-3xl">🎨</span>
          </div>
          <div>
            <h2 class="text-xl font-semibold mb-2">{{ learningSet.name }}</h2>
            <p class="text-gray-600">{{ learningSet.description_short }}</p>
          </div>
        </div>
      </div>

      <!-- Price Section -->
      <div class="p-8 border-b">
        <div class="flex justify-between items-center mb-4">
          <span class="text-gray-600">Стоимость набора</span>
          <div class="flex items-center gap-3">
            <span class="text-gray-400 line-through">1490₽</span>
            <span class="text-2xl font-bold text-purple-600">990₽</span>
          </div>
        </div>
        <div class="bg-yellow-50 p-4 rounded-lg">
          <div class="flex items-start gap-3">
            <div class="text-yellow-500 text-xl">⚡</div>
            <div>
              <p class="font-medium text-yellow-800">Специальное предложение</p>
              <p class="text-yellow-700 text-sm">Скидка 34% действует ограниченное время</p>
            </div>
          </div>
        </div>
      </div>

      <!-- What's Included -->
      <div class="p-8 border-b">
        <h3 class="text-lg font-semibold mb-4">Что входит в набор:</h3>
        <ul class="space-y-3">
          <li class="flex items-center gap-3">
            <div class="text-purple-500">✓</div>
            <span>Цветная мандала</span>
          </li>
          <li class="flex items-center gap-3">
            <div class="text-purple-500">✓</div>
            <span>Цифровая мандала</span>
          </li>
          <li class="flex items-center gap-3">
            <div class="text-purple-500">✓</div>
            <span>3 точечных мандалы</span>
          </li>
          <li class="flex items-center gap-3">
            <div class="text-purple-500">✓</div>
            <span>Инструкция по работе с мандалами</span>
          </li>
        </ul>
      </div>

      <!-- Email Input Section -->
      <div class="p-8 border-b">
        <div class="mb-4">
          <label for="email" class="block text-sm font-medium text-gray-700 mb-2">
            Email на который пришлем набор для рисования после оплаты
          </label>
          <input
            type="email"
            id="email"
            v-model="email"
            class="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500 outline-none transition-colors"
            :class="{ 'border-red-500': emailError }"
            placeholder="your@email.com"
            @input="emailError = ''"
          />
          <p v-if="emailError" class="mt-2 text-sm text-red-600">{{ emailError }}</p>
        </div>
      </div>

      <!-- Purchase Button -->
      <div class="p-8">
        <button
          @click="handlePurchase"
          class="w-full bg-purple-600 text-white py-4 rounded-lg font-semibold text-lg hover:bg-purple-700 transition-colors"
          :disabled="isLoading"
        >
          <template v-if="isLoading">
            <span class="flex items-center justify-center gap-2">
              <span class="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full"></span>
              Обработка...
            </span>
          </template>
          <template v-else>
            Купить сейчас
          </template>
        </button>
        <p class="text-center text-sm text-gray-500 mt-4">
          Нажимая кнопку "Купить сейчас", вы соглашаетесь с условиями покупки
        </p>
      </div>
    </div>

    <!-- Loading State -->
    <div v-else-if="isLoading" class="flex justify-center items-center min-h-[400px]">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
    </div>

    <!-- Error State -->
    <div v-else class="text-center py-12">
      <p class="text-red-500">Не удалось загрузить информацию о наборе</p>
      <button
        @click="fetchLearningSet"
        class="mt-4 text-purple-600 hover:text-purple-700"
      >
        Попробовать снова
      </button>
    </div>
  </div>
</template>
